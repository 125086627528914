.content.static {
    width: 100%;
    font-size: 14px;
    color: #000;
    line-height: 1.6;
}

.content.static .contentWrapper {
    max-width: 960px;
    padding: 60px 0;
    text-align: center;
    margin: 0 auto 60px;
}

.content.static h2,
.content.static h3,
.content.static h4,
.content.static h5 {
    font-family: 'Nunito-ExtraBold';
    color: #000;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.content.static h2 {
    font-size: 32px;
    letter-spacing: 4px;
}

.content.static h3 {
    font-size: 28px;
    letter-spacing: 3px;
}

.content.static h4 {
    font-size: 24px;
    letter-spacing: 2px;
}

.content.static h5 {
    font-size: 18px;
}

.content.static p {
    font-size: 14px;
    color: #888;
    line-height: 1.6;
    margin: 0 auto 20px;
}

.content.static ul {
    list-style-type: none;
}
.content.static ul > li {
    text-indent: -5px;
    line-height: 1.5;
    margin-bottom: 5px;
}
.content.static ul > li:before {
    content: '- ';
    text-indent: -5px;
}

.noSpace {
    margin: 0 auto !important;
}

.content.static p.alignLeft {
    text-align: left;
}

.paddedColumn {
    width: 80%;
    margin: 0 auto;
}

.content.static .image {
    width: 80%;
    margin: 0 auto 20px;
    overflow: hidden;
}

.content.static .image img.full {
    width: 100%;
}

.content.static .image img.half {
    width: 48%;
    float: left;
}

.content.static .image img.half:first-child {
    margin-right: 4%;
}

.content.static .defBtn {
    width: auto;
    height: auto;
    font-family: 'MrsEavesRoman-Regular';
    font-size: 20px;
    letter-spacing: 3px;
    line-height: normal;
    background: none;
    border: 1px solid #a37b2c;
    color: #a37b2c;
    text-transform: uppercase;
    padding: 15px 20px;
    display: inline-block;
    margin: 0 auto 30px;
}

.content.static .defBtn:hover {
    background: #a37b2c;
    color: #fff;
}

.contactBox {
    width: 80%;
    border: 3px solid #a37b2c;
    padding: 20px 30px;
    margin: 0 auto;
}

.subHeader {
    font-size: 16px !important;
}

.inputPanel {
    width: 35%;
    display: inline-block;
    margin: 0 10px 30px;
}

.inputPanel span {
    color: #888;
    display: block;
    text-align: left;
    margin-bottom: 5px;
}

.inputPanel input,
.inputPanel textarea {
    width: 100%;
    padding: 5px;
    color: #888;
}

.greyBtn {
    background: #666;
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 30px;
    display: inline-block;
}

.goldBtn {
    background: #a37b2c;
    font-size: 20px;
    color: #fff;
    letter-spacing: 2px;
    line-height: 2;
    padding: 10px 50px;
    display: inline-block;
}

.goldBtn span {
    font-family: 'Nunito-Bold';
}

.doubleborderBtn {
    font-size: 24px;
    color: #a37b2c;
    position: relative;
}

.doubleborderBtn:before {
    content: '';
    width: 800px;
    height: 100px;
    border: 2px solid #a37b2c;
    position: absolute;
    top: -25px;
    left: -28px;
    display: block;
}

.doubleborderBtn:after {
    content: '';
    width: 800px;
    height: 100px;
    border: 2px solid #a37b2c;
    position: absolute;
    top: -20px;
    left: -23px;
    display: block;
}

.doubleborderBtn span {
    font-family: 'Nunito-Bold';
    font-size: 35px;
    color: #a37b2c;
    text-transform: uppercase;
}

.featureBox {
    width: 100%;
    margin: 0 auto 50px;
}

.featurePanel {
    width: 24%;
    display: inline-block;
    vertical-align: top;
}

.featurePanel .caption {
    padding: 0 20px;
}

.featurePanel p {
    display: block;
}

.featurePanel .emailLink {
    color: #a37b2c;
    margin-top: 30px;
    display: block;
}

.featurePanel .defBtn {
    font-size: 15px !important;
    padding: 10px 15px !important;
}

.defLink {
    font-size: 16px;
    color: #a37b2c;
}

.underlined {
    font-size: inherit;
    text-decoration: underline;
}

.bottomBorder {
    margin-bottom: 100px !important;
    display: block;
}

.bottomBorder:after {
    width: 100px;
    background: #a37b2c;
    content: '';
    display: block;
    height: 1px;
    margin: 25px auto 0;
}

.bottomBorder.leftMode {
    text-align: left;
}

.authBanner {
    background: #666;
}

.authBanner img {
    width: 100%;
}

.authBox {
    width: 700px;
    margin: 80px auto 80px;
    overflow: hidden;
}

.authBox .logo {
    width: 160px;
    height: 160px;
    float: left;
    border-right: 1px solid #a37b2c;
    padding-right: 20px;
}

.authBox .logo img {
    width: 100%;
}

.authBox .caption {
    width: 540px;
    display: block;
    text-align: left;
    float: left;
    padding-left: 20px;
}

.authBox .caption h3 {
    text-align: left;
    margin-bottom: 0;
}

.authBox .caption .motto {
    font-style: italic;
    color: #a37b2c;
    margin-bottom: 10px;
}

.promiseBox {
    border-top: 1px solid #a37b2c;
    text-align: left;
    padding: 50px 100px;
}

.promiseBox .panel {
    display: block;
    overflow: hidden;
    text-align: center;
}

.promiseBox .panel .image {
    width: 20%;
    max-width: 100px;
    height: 100px;
    display: inline-block;
    margin-right: 50px;
    vertical-align: middle;
}

.promiseBox .panel .caption {
    width: 70%;
    display: inline-block;
    vertical-align: middle;
}

.promiseBox .heading {
    font-size: 16px !important;
    color: #666 !important;
    margin-bottom: 0 !important;
    text-transform: uppercase;
}

.promiseBox p {
    text-align: left;
}

.sectionGrey {
    display: block;
    width: 960px;
    height: 360px;
    margin: 40px auto 0;
    background: #e5e5e5;
    text-align: center;
    vertical-align: middle;
}

.returnpolicyHeader {
    width: 100%;
    padding: 100px 0 0;
}

.returnpolicyHeader h2 {
    margin-bottom: 50px !important;
    display: block;
}

.returnpolicyHeader .image {
    width: 100% !important;
}

.returnpolicyHeader .image img {
    width: 100%;
}

.careersPoint {
    margin-bottom: 80px;
}

.careersPosition .subHeader {
    font-family: 'Nunito-Regular';
    font-size: 20px !important;
    margin-bottom: 60px !important;
}

.careersPosition .position {
    width: 100%;
    display: block;
    margin: 0 auto 60px;
    text-align: center;
}

.careersPosition .position .panel {
    display: inline-block;
    margin-right: 80px;
    margin-bottom: 30px;
}

.careersPosition .position .panel:nth-child(3n) {
    margin-right: 0;
}

.careersPosition .position .panel p {
    ont-family: 'Nunito-Regular';
    font-size: 18px;
    margin: 0;
}

.careersPosition .position .panel a {
    ont-family: 'Nunito-Bold';
    font-size: 18px;
    color: #a37b2c;
}

.careersPosition .careerUnderlined {
    font-size: 20px;
    text-decoration: underline;
    margin-bottom: 100px;
    display: block;
}

.textimageWrap {
    display: block;
    margin: 30px 30px 0;
    overflow: hidden;
    padding-bottom: 30px;
    width: 100%;
}

.textimageWrap .caption {
    display: block;
    margin: 15px 0;
    overflow: hidden;
    padding: 0 15px;
    width: 49%;
    text-align: justify;
}

.textimageWrap.left .caption {
    float: right;
}

.textimageWrap.right .caption {
    float: left;
}

.imageArea {
    display: block;
    overflow: hidden;
    width: 49%;
}

.textimageWrap.left .imageArea {
    float: left;
    margin-right: 2%;
}

.textimageWrap.right .imageArea {
    float: right;
    margin-left: 2%;
}

.imageBox {
    background: #333;
    display: block;
}

.imageBox img {
    width: 100%;
    display: block;
}

.anchor {
    font-size: 18px;
    color: #a37b2c;
    text-transform: uppercase;
    margin: 100px auto;
    display: block;
    float: none;
}

.anchor:after {
    content: '';
}

.returnStep,
.howsellStep {
    width: 100%;
    text-align: center;
    margin: 0 auto 100px;
    display: block;
}

.returnStep li {
    width: 25%;
    display: inline-block;
    text-align: left;
    margin: 0 -2px;
    padding: 0 15px 0 5px;
    vertical-align: top;
    position: relative;
    text-align: center;
}

.returnStep li img {
    width: 138px;
    height: 138px;
    margin: 0 0 20px 40px;
}

.returnStep li .heading {
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0;
    color: #a37b2c;
    text-transform: uppercase;
    height: 50px;
    overflow: hidden;
}

.howsellStep li {
    width: 25%;
    display: inline-block;
    text-align: left;
    margin: 0 -2px;
    padding: 0 5px;
    vertical-align: top;
    position: relative;
}

.howsellStep li:after,
.returnStep li:after {
    content: '';
    width: 10px;
    height: 14px;
    background: url(/images/arrow_right_def.png) no-repeat top center;
    position: absolute;
    top: 50px;
    right: -10px;
    color: #888;
}

.howsellStep li:last-child:after,
.returnStep li:last-child:after {
    display: none;
}

.howsellStep p {
    text-align: center;
}

.contentWrapper.agreementWrapper ol,
ul {
    list-style-position: inside;
}

.contentWrapper.agreementWrapper ol {
    list-style-type: decimal;
}

.howsellStep li a {
    display: block;
    text-align: center;
    font-family: 'MrsEavesRoman-Regular';
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #a37b2c;
}

.howsellStep li a:hover {
    text-decoration: underline;
}

.howsellStep li img {
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;
}

.howsellStep li .heading {
    font-family: 'MrsEavesRoman-Regular';
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #a37b2c;
    margin: 0 0 5px;
    padding: 0 0 0 20px;
    position: relative;
    height: 50px;
    overflow: hidden;
}

.howsellStep li .heading span {
    position: absolute;
    top: 0;
    left: 0;
    font-family: "MrsEavesRoman-Regular";
}

.howsellStep li .caption {
    padding-left: 20px;
}

.newArrival {
    width: 100%;
    margin-top: 60px;
}

.newArrival:before {
    width: 80%;
    background: #a37b2c;
    content: '';
    display: block;
    height: 1px;
    margin: 0 auto 60px;
}

.itemBox {
    text-align: center;
    margin: 50px auto 0;
    display: block;
}

.itemPanel {
    width: 33%;
    display: inline-block;
    text-align: left;
    padding: 0 58px;
}

.itemPanel .image {
    width: 200px !important;
    height: 200px !important;
    background: #fff;
    display: block;
}

.itemPanel .image img {
    width: 100%;
}

.itemPanel p {
    margin: 0 !important;
}

.itemPanel .name {
    color: #333 !important;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 5px !important;
    display: block;
}

.itemPanel .type {
    font-size: 12px !important;
}

.itemPanel .price {
    font-weight: 700;
    color: #a37b2c !important;
}

.itemPanel .desc p {
    display: inline-block;
    font-size: 12px;
}

.itemPanel .desc .size {
    border-right: 1px solid #888;
    padding-right: 5px;
}

.itemPanel .desc span {
    color: #333;
    font-weight: 600;
}

.infoBtn {
    width: 14px;
    height: 14px;
    background: url(/images/icon_info.png) no-repeat center center;
    display: inline-block;
    margin-left: 5px;
}

.shippingWrapper {
    text-align: left;
}

.shippingWrapper .subHeader {
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 !important;
}

.tableWrapper {
    width: 100%;
    max-width: 960px;
    margin: 0 auto 40px;
}

.tableWrapper table {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
}

.tableWrapper p {
    margin: 0;
}

.tableWrapper th,
.tableWrapper td {
    border: 1px solid #888;
    padding: 15px;
    text-align: left;
}

.tableWrapper th {
    font-family: 'MrsEavesRoman-Regular';
    font-size: 18px;
    color: #a37b2c;
    text-transform: uppercase;
}

.tableWrapper td {
    font-size: 16px;
    color: #888;
}

.pressWrapper {
    width: 100%;
    margin: 0 auto;
    padding: 80px 0;
}

.pressWrapper h2 {
    font-size: 40px !important;
    margin-bottom: 50px !important;
}

.pressBox {
    width: 100%;
    text-align: center;
}

.pressPanel {
    width: 233px;
    height: 150px;
    display: inline-block;
    border: 1px solid #a37b2c;
    margin: 0 10px 25px;
}

.sellerPoint {
    margin-bottom: 30px;
}

.pointBox {
    text-align: center;
}

.pointPanel {
    width: 19%;
    display: inline-block;
    border: 1px solid #a37b2c;
    padding: 5px;
    margin-right: 5px;
    vertical-align: top;
}

.pointPanel.noBorder {
    border: none;
}

.pointPanel:nth-child(5n) {
    margin-right: 0;
}

.pointPanel .image {
    max-width: 95px;
    width: 100%;
    overflow: hidden;
}

.pointPanel .image img {
    width: 100%;
}

.pointPanel p {
    margin: 0 !important;
}

.static_title {
    color: #a37b2c;
    font-family: 'Nunito-Bold';
    text-transform: uppercase;
    text-align: left;
    font-size: 18px;
    padding: 10px 0;
    border-bottom: 1px solid #a37b2c;
    margin: 80px 0;
}

.static_title.clothes {
    background: url(/images/icon_women_clothes_gold.png) no-repeat right 3% center, url(/images/icon_men_clothes_gold.png) no-repeat right center;
}

.static_title.acc {
    background: url(/images/icon_women_acc_gold.png) no-repeat right 3% center, url(/images/icon_men_acc_gold.png) no-repeat right 7% center, url(/images/icon_kids_acc_gold.png) no-repeat right center;
}

.static_title.watches {
    background: url(/images/icon_watches_gold.png) no-repeat right center;
}

section#static {
    padding: 0;
}

.pnfBox {
    font-size: 28px;
    font-family: 'Nunito-Bold';
    color: #888;
}

.pnfBox span {
    font-size: 200px;
    color: #e6e6e6;
    display: block;
}

.pnfBox a {
    width: 240px;
    height: 36px;
    line-height: 36px;
    margin: 30px auto 0;
    color: #fff;
    background: #a37b2c;
    font-size: 16px;
    display: block;
}

.storyBox {
    width: 960px;
    margin: 0 auto;
    overflow: hidden;
}

.storyBox .leftSpacer {
    width: 240px;
    float: left;
}

.storyBox .imageBox {
    width: 360px;
    height: 224px;
    margin: 20px;
    background: #ddd;
    float: left;
}

.storyBox .imageBox img {
    width: 100%;
}

.storyBox .caption {
    width: 540px;
    display: block;
    float: left;
    text-align: left;
    padding: 40px 0 40px 20px;
    border-left: 1px solid #a37b2c;
}

.storyBox .caption h3 {
    text-align: left;
    margin-bottom: 0;
}

@media only screen and (max-width: 640px) {
    .howsellStep li .heading {
        padding: 0;
    }
}

@media only screen and (max-width: 767px) {
    .storyBox {
        width: 100% !important;
    }
    .storyBox .imageBox,
    .storyBox .leftSpacer {
        padding: 0 !important;
        margin: 0 auto 20px !important;
        border: none !important;
        float: none !important;
    }
    .storyBox .caption {
        width: 100% !important;
        padding: 0 !important;
        float: none !important;
        text-align: center !important;
    }
    .storyBox .caption h3 {
        text-align: center !important;
    }
}

.goldBorder {
    border: 1px solid #a37b2c;
    padding: 6px 10px;
    display: inline-block;
}
